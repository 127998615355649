import React, { useState, useEffect } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfinity } from "@fortawesome/free-solid-svg-icons"
import { faAtom } from "@fortawesome/free-solid-svg-icons"
import "bootstrap/dist/css/bootstrap.min.css"
import { faAward } from "@fortawesome/free-solid-svg-icons"
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons"
import "./Workpage.css";
import Layout from '../components/layout';
import SEO from "../components/seo"

const Workpage = () => {
  const [work, setWork] = useState(null);
  const [icons,setIcons] =useState([faInfinity,faAward,faAtom])
  useEffect(() => {
    console.log("inside Component")
    const apiUrl = `https://api-vikashchander.vercel.app/works`
    // fetch(apiUrl)
    // .then((res) => res.json())
    // .then((repos) => console.log(repos.data.Projects)
    //   );
    axios({ method: "get", url: apiUrl }).then(res =>
      setWork([...res.data.WorkExperience])
    )
  }, [setWork,setIcons])
  return (
    <React.Fragment>
       <Layout>
        <SEO title="Intern" />
        <div id='work'>
        <h3 className="big-text ml-4 box-underline work-header">
          <FontAwesomeIcon
            icon={faProjectDiagram}
            color="black"
            className="heart mr-2"
          />
          Work Experience <span>AS (Intern)</span>
        </h3>
        <div className="main-timeline">
            {work === null ? (
              <p>loading ....</p>
            ) : (
              work.map((data,index) => (
                <div className="timeline" key={index}>
                  <div className="timeline-icon">
                    <FontAwesomeIcon

                        icon={icons[Math.floor(Math.random()*3)]}
                      color="SteelBlue"
                      className="mt-1"
                    />
                  </div>
                  <div className="timeline-content">
              <h3 className="title">{data.Title}</h3>
              <span className="ml-1">{data.Date}</span>
                    <p className="mt-2 description text-capitalize">
                      worked as
                      <span className="mx-2 text-info font-weight-bold ">
                        {data.JobProfile},
                      </span>
                     {data.WorkDetails}
                    </p>
                  </div>
                  </div>
              ))
            )}
        </div>
        </div>
        </Layout>
    </React.Fragment>
  )
}
export default Workpage
